import { api } from './api'

type DeploymentMetadata = {
  creationTimestamp: Date
  generation: number
  name: string
  namespace: string
}

type ReplicaSetMetadata = {
  name: string
  namespace: string
  creation_timestamp: Date
  owner_references: {
    kind: string
    name: string
  }[]
}

type CronJobMetadata = {
  name: string
  namespace: string
  creation_timestamp: Date
  owner_ref_kind: string
  owner_ref_name: string
}

type PodsMetadata = {
  name: string
  namespace: string
  ownerName: string
  ownerRefKind: string
}

type DeploymentCondition = {
  status: string
  last_transition_time: Date
  last_update_time: Date
  type: string
  message: string
  reason: string
}

type CronJobCondition = {
  status: string
  last_transition_time: Date
  last_probe_time: Date
  type: string
  message: string
  reason: string
}

type PodsCondition = {
  lastTransitionTime: Date
  message: string
  reason: string
  status: string
  type: string
}

type DeploymentStatus = {
  availableReplicas: number
  conditions: DeploymentCondition[]
  observedGeneration: number
  readyReplicas: number
  replicas: number
  updatedReplicas: number
}

type CronJobStatus = {
  start_time: string
  completion_time: string
  failed: number
  ready: number
  active: number
  succeeded: number
  terminating: number
  conditions: CronJobCondition[]
}

type TerminatedPods = {
  containerId: string
  exitCode: number
  finishedAt: Date
  message?: string
  reason: string
  signal?: string
  startedAt: Date
}

type PodsStatus = {
  conditions: PodsCondition[]
  containerStatuses: {
    image: string
    phase: string
    state: {
      startedAt: string
      status: string
    }
    lastState: {
      running: unknown
      waiting: unknown
      terminated: TerminatedPods
    }
  }
}

type DeploymentResponse = {
  metadata: DeploymentMetadata
  status: DeploymentStatus
}

type ReplicaSetResponse = {
  metadata: ReplicaSetMetadata
  status: {
    replicas: number
    observed_generation: number
  }
}

type CronJobResponse = {
  metadata: CronJobMetadata
  status: CronJobStatus
}

type PodsResponse = {
  metadata: PodsMetadata
  status: PodsStatus
}

const monitoringApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDeployments: builder.query<DeploymentResponse[], void>({
      query: () => ({
        url: 'k8s-client/v1/deployments'
      })
    }),
    getPods: builder.query<PodsResponse[], void>({
      query: () => ({
        url: 'k8s-client/v1/pods'
      })
    }),
    getCronJobs: builder.query<CronJobResponse[], void>({
      query: () => ({
        url: 'k8s-client/v1/cron-jobs'
      })
    }),
    getReplicas: builder.query<ReplicaSetResponse[], void>({
      query: () => ({
        url: 'k8s-client/v1/replica-sets'
      })
    })
  })
})

export const { useGetDeploymentsQuery, useGetPodsQuery } = monitoringApi
