import React, { useEffect, useState } from 'react'
import { Alert, Button, Input, Row, Spinner, Table } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useGetDeploymentsQuery } from 'src/services/monitoring'
import { formatAge } from 'src/utils'

const Deployment = () => {
  const { t } = useTranslation()
  // const testNamespaces = ['ru-backend', 'en-backend']

  const [controllerName, setControllerName] = useState('')
  const [namespaces, setNamespaces] = useState<string[]>([])
  const [selectedNamespace, setSelectedNamespace] = useState<string>('')

  const { data: deployment, isLoading, error } = useGetDeploymentsQuery()

  const fieldsDescription = [
    t('metadata_name'),
    t('namespace'),
    t('ready'),
    t('number_of_pods'),
    t('controller_age')
  ]

  useEffect(() => {
    if (deployment) {
      const uniqueNamespaces = new Set(
        deployment.map((d) => d.metadata.namespace)
      )

      setNamespaces(Array.from(uniqueNamespaces))
      setSelectedNamespace(Array.from(uniqueNamespaces)[0])
    }
  }, [deployment])

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center mt-5 mb-5'>
        <Spinner color='primary' />
      </div>
    )
  }

  if (error) {
    return (
      <Alert
        color='warning'
        className='d-flex justify-content-center mt-5 mb-5'
      >
        <strong> {JSON.stringify(error)} </strong>
      </Alert>
    )
  }

  if (!deployment) {
    return null
  }

  const filteredDeployment = deployment.filter((item) => {
    const matchesName = item.metadata.name
      .toLowerCase()
      .includes(controllerName.toLowerCase())
    const matchesNamespace = item.metadata.namespace === selectedNamespace

    return matchesName && matchesNamespace
  })

  return (
    <div className='d-flex flex-column gap-30'>
      <Row className='d-flex ms-1'>
        <Input
          type='text'
          className='form-control form-control-sm me-3'
          style={{ width: '200px', height: '30px' }}
          onChange={(e) => setControllerName(e.target.value)}
          value={controllerName}
        />
        <Input
          type='select'
          className='form-select form-select-sm me-3'
          style={{ width: '200px', height: '30px' }}
          value={selectedNamespace}
          onChange={(e) => setSelectedNamespace(e.target.value)}
        >
          {namespaces.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Input>
        <Button
          color='primary'
          style={{
            backgroundColor: '#405189',
            width: '200px',
            padding: '4px'
          }}
          type='submit'
          onClick={() => setControllerName('')}
        >
          {t('Clear')}
        </Button>
      </Row>
      <Table hover className='table-sm align-middle table-nowrap mb-0 mt-4'>
        <thead>
          <tr>
            {fieldsDescription.map((fieldName) => (
              <th className='ps-3' key={fieldName} scope='col'>
                {fieldName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredDeployment.map((item) => (
            <tr key={item.metadata.name}>
              <td className='ps-3'>{item.metadata.name}</td>
              <td className='ps-3'>{item.metadata.namespace}</td>
              <td style={{ paddingLeft: '60px' }}>
                {item.status.readyReplicas || 0} / {item.status.replicas || 0}
              </td>
              <td style={{ paddingLeft: '110px' }}>
                {item.status.updatedReplicas || 0}
              </td>
              <td className='ps-3'>
                {formatAge(item.metadata.creationTimestamp)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default Deployment
